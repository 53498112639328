<template>
    <div :id="name" v-show="open" class="gr-modal" @click="cancel">
      <div @click.stop :class="`modal-content ${size}`" :title="title" @shown="shown" @hidden="hidden">
        <div class="modal-header" v-if="!hideHeader">
          <div class="d-flex justify-content-end mb-2" v-if="help">
            <a :href="help" target="_blank" style="margin-right: 10px"><img v-b-tooltip.hover title="Veja como fazer!"
                class="img-icon" src="@/assets/img/icons/help-circle.svg" alt="icon" /></a>
            {{ title }}
          </div>
          <div v-else>
            {{ title }}
          </div>
  
          <button @click="hidden()" class="close"></button>
        </div>
        <slot></slot>
  
        <div class="modal-footer" v-if="!hideFooter">
          <slot name="footer" :cancel="cancel"> </slot>
        </div>
      </div>
    </div>
  </template>
<script setup>
import { ref, defineProps, defineExpose, defineEmits, onUnmounted, nextTick, getCurrentInstance } from 'vue';
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  help: {
    type: String,
  },
  size: {
    type: String,
    default: 'lg',
  },
  title: {
    type: String,
    required: true,
  },
  hideFooter: {
    type: Boolean,
  },
  hideHeader: {
    type: Boolean,
    default: false,
  },
  noBackdropClose: {
    type: Boolean,
  },
  noEscClose: {
    type: Boolean,
  },
});

const vm = getCurrentInstance().proxy;
const open = ref(false);
const emit = defineEmits(['shown', 'hidden']);
const cancel = () => {
  if (!props.noEscClose) {
    document.removeEventListener('keydown', handleEscKey);
  }
  emit('hidden');
  open.value = false;
};

const shown = async () => {
  await nextTick();
  open.value = true;
  emit('shown', true);
  document.querySelector('body').style = 'overflow: hidden;';
  if (!props.noEscClose) {
    document.addEventListener('keydown', handleEscKey);
  }
};

const hidden = () => {
  if (props.noBackdropClose) {
    return;
  }
  if (!props.noEscClose) {
    document.removeEventListener('keydown', handleEscKey);
  }
  emit('hidden');
  open.value = false;
  document.querySelector('body').style = 'overflow: auto !important;';
};


const handleEscKey = (event) => {
  if (event.key !== 'Escape') {
    return;
  }
  emit('hidden');
  open.value = false;
};

onUnmounted(() => {
  if (!props.noEscClose) {
    document.removeEventListener('keydown', handleEscKey);
  }
});

defineExpose({ shown, hidden });
</script>

  
  <style scoped>
  .gr-modal {
    display: grid;
    place-content: center;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #0000007d;
    z-index: 999;
    overflow: auto;
    border: none;
  }
  .modal-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--white-medium);
    border-radius: 0;
    padding-left: 0;
    font-weight: 600;
    margin-bottom: 25px;
  }
  .close {
    background: url(../assets/img/icons/fechar.svg) no-repeat 50%;
    overflow: hidden;
    text-indent: -100px;
    color: transparent;
    outline: none;
    border: none;
  }
  .modal-content {
    border-radius: 10px !important;
    padding: 20px 30px !important;
    animation: showTop 0.4s forwards !important;
    width: 100vw;
  }
  .modal-footer {
    display: flex;
    align-items: center;
    margin-top: 20px;
    z-index: -1;
  }
  #Modal-Templates .modal-content{
    height: 80vh;
  }
  
  #edicao-rapida .modal-content {
    height: 80vh;
  }
  
  .modal-content .modal-header {
    border-bottom: 1px solid var(--white-medium);
    border-radius: 0;
    padding-bottom: 25px;
    padding-left: 0;
  }
  .modal-content .modal-header h5 {
    font-size: 16px;
    font-weight: 600;
  }
  .modal {
    padding: 20px 0;
  }
  .col-auto {
    padding: 0;
  }
  .modal-content .modal-header .close {
    background: url("../assets/img/icons/fechar.svg") no-repeat center center;
    overflow: hidden;
    text-indent: -100px;
    color: transparent;
    outline: none;
    border: none;
  }
  .modal-content .modal-footer {
    border-top: 1px solid var(--white-medium);
    border-radius: 0;
    padding-top: 25px;
  }
  
  /* modal 100% */
  #product-edit {
    padding: 0 !important;
  }
  #product-edit .modal-xl {
    margin: 0 auto;
    max-width: 100vw;
  }
  @media (min-width: 1600px) {
    #product-edit .modal-xl {
      max-width: 1300px;
    }
  }
  #product-edit .modal-content {
    border-radius: 0 !important;
  }
  #modal-info-aprovacao footer {
    display: none !important;
  }
  
  /* Extra Small Modal */
  .xs {
    max-width: 400px;
  }
  
  /* Small Modal */
  .sm {
    max-width: 300px;
  }
  
  /* Medium Modal */
  .md {
    max-width: 500px;
  }
  
  /* Large Modal */
  .lg {
    max-width: 800px;
  }
  
  /* Extra Large Modal */
  .xl {
    max-width: 1140px;
  }
  
  /* Extra Extra Large Modal */
  .xxl {
    max-width: 1300px;
  }

  /* Super Large Modal */
  .sl {
    max-width: 95vw;
  }  
  </style>